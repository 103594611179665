/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

export interface Local {
  lang: string;
  data: object;
}

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor(private translateService: TranslateService) {}

  public addLangs(langs: string[]): void {
    this.translateService.addLangs(langs);
  }

  public setDefaultLang(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  public use(lang: string): Observable<any> {
    return this.translateService.use(lang);
  }

  public translate(...args: Local[]): void {
    const locals = [...args];

    locals.forEach((local) => {
      this.translateService.setTranslation(local.lang, local.data, true);
    });
  }
}
